.Home {
    display: flex;
    flex-flow: column;
    // min-height: 300vh;
    background-color: var(--bg-color);
    
    .MainBanner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background-color: var(--banner-color);
        background-size: cover;
        min-height: calc(100vh - 55px);
        width: 100%;
    }
    
    .MainBannerText {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        width: 100%;
        > div {
        padding: 0 calc(min(40px, 5%));
        > p {
            font-family: 'Poppins', sans-serif;
            font-size: 75px;
        }
        .BannerButton {
            margin: 30px 0;
            padding: 0px 15px 5px 15px;
            font-size: 35px;
            border: solid white 3px;
            border-radius: 30px;
            width: fit-content;
            cursor: pointer;
            user-select:none;
        }
        }
    }
    
    .MainBannerImage {
        background-image: url("../../assets/Lomo-Saltado-Bowl.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
    
    @media only screen and (max-width: 700px) {
        .MainBanner {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        }
        .MainBannerText {
        > div {
            > p {
            font-family: 'Poppins', sans-serif;
            font-size: 55px;
            }
            .BannerButton {
            }
        }
        }
        .MainBannerImage {
        background-position: unset;
        }
    }
}