
.Spacer {
    width: 100%;
    height: 60px;
    // background-color: #111111;
}

.OrderContainer {
    position: fixed;
    top: 0px;
    display: flex;
    justify-content: center;
    z-index: 999;
    height: 60px;
    width: 100%;
    background-color: #111111;
    // box-shadow: 0 3px 3px 0 rgb($color: #111111, $alpha: 22%);

    .Order {
        display: flex;
        width: 100%;
        max-width: calc(var(--max-width) + 200px);
        justify-content: flex-end;
        align-items: center;
        padding: 0 15px;
        
        .OrderButton {
            padding: 2.5px 10px;
            font-size: 25px;
            font-weight: 500;
            border-radius: 20px;
            width: fit-content;
            cursor: pointer;
            user-select:none;
            background-color: var(--primaryColor);
            color: white;
            text-decoration: none;
        }
    }
}

.SideOder {
    z-index: 999;
    position: fixed;
    width: min(100%, 400px);
    height: 100vh;
    transition: right 0.5s;
    // background-color: rgba($color: config.$banner-color, $alpha: 1);
    
    .Background {
        position: absolute;
        height: 100%;
        width: 100%;
        backdrop-filter: blur(5px) brightness(0.15);
    }

    .Nav {
        position: fixed;
        display: flex;
        align-items: center;
        height: 55px;
        width: 100%;
        // background-color: white;
        color: white;
        padding: 0 15px;

        .xIcon {
            width: 35px;
        }
    }

    .Main {
        position: relative;
        // background-color: white;
        height: calc(100% - 55px);
        width: 100%;
    }
}

.Open {
    right: 0;
}

.Close {
    right: max(-100%, -400px);
}