
.StoreBannerContainer {
  display: flex;
  flex-flow: column;
  width: 100%;

  .ContactsContainer {
    display: flex;
    gap: 64px;
    justify-content: center;
    flex-flow: row wrap;
    width: 100%;
    padding: 125px 45px 200px 45px;
    font-size: 25px;

    background-image: url("../../assets/food/SaborAPeru_Hero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // background-attachment: fixed;
    

    > div {
      flex: 1;
      display: flex;
      align-items: center;  
      justify-content: center;
      // border: 3px solid rgba(0,0,0,0.95);
      box-shadow: 0 6px 8px 0 rgba(0,0,0,0.2);
      margin: 20px;
      border-radius: 12px;
      max-width: 500px;
      min-width: min(300px, 100%);
      background-color: rgba($color: #fff, $alpha: 0.95);
    }

    .bold {
      font-weight: bold;
    }
      
    .no-wrap {
      white-space: nowrap;
    }

    .PhoneNumber {
      padding: 20px;
      text-align: center;
    }

    .Address {
      padding: 20px;
      text-align: center;
    }
  }

  .Background {
    display: flex;
    background-image: url("../../assets/resturant_inside.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // background-attachment: fixed;
    // min-height: 350px;
    height: 800px;
    max-height: 80vh;
    width: 100%;

    .StoreBanner {
      flex: 1;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: flex-end;

      .Text {
        display: flex;
        flex-flow: column;
        justify-content: center;
        height: fit-content;
        max-width: 500px;
        background-color: rgba($color: #fff, $alpha: 0.95);
        // border: 3px solid rgba(0,0,0,0.95);
        border-radius: 12px;
        box-shadow: 0 6px 8px 0 rgba(0,0,0,0.2);
        margin: 0 20%;
        padding: 40px;
        h1 {
          font-size: 64px;
          // font-size: 72px;
          // color: gold;
        }
        p {
          font-size: 32px;
          // color: white;
          // font-weight: 500;
        }
      }
    }
      
    .DishImg {
      flex: 1;
      width: 100%;
      background-image: url("../../assets/MIXED DISHES.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      filter: grayscale(20%);

    }
  }

  @media only screen and (max-width: 700px) {
    .ContactsContainer {
      gap: 0px;
      padding: 25px;
      font-size: 20px;
    }



    .Background {
      flex-flow: column;

      .StoreBanner {
        .Text {
          padding: 30px;
          margin: 0 10%;
          h1 {
            font-size: 40px;
          }
          p {
            font-size: 24px;
          }
        }
      }
      .DishImg {
        aspect-ratio: 1/1;
      }
    }
  }
}