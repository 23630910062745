
$primaryColor: #e06d3a;
// $primaryColor: #e25111;
$max-width: 900px;
$small: 700px;
$bg-color: #f1f1f1;
$banner-color: #111;

:root {
  --primaryColor: #e06d3a;
  // $primaryColor: #e25111;
  --max-width: 900px;
  --small: 700px;
  --bg-color: #f1f1f1;
  --banner-color: #111;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // user-select:none;
  // outline:none;
}

.no_highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}